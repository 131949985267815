.miles-electric{
    position: relative;
    text-align: center;
}
.miles-electric:before{
	 content: "";
    position: relative;
    display: inline-block;
    width: 0; height: 100%;
    vertical-align: middle;
}

.miles-logo-circles{

	display: inline-block;
    vertical-align: middle;
}

.miles-logo-static{

	margin:auto;
	text-align: center;
}

.miles-logo-circle{position:absolute;animation:miles-logo-circle 1s linear infinite;will-change:transform}

.miles-logo-circle:nth-child(1){animation-duration:30s}

.miles-logo-circle:nth-child(2){animation-duration:40s}

.miles-logo-circle:nth-child(3){animation-duration:50s}

.miles-logo-circle:nth-child(4){animation-duration:60s}

.miles-logo-circle:nth-child(5){animation-duration:70s}

.miles-logo-circle:nth-child(6){animation-duration:80s}

.miles-logo-circle:nth-child(7){animation-duration:90s}

.miles-logo-circle:nth-child(8){animation-duration:100s}

.miles-logo-circle:nth-child(9){animation-duration:110s}

.miles-logo-circle:nth-child(10){animation-duration:120s}

@keyframes miles-logo-circle{100%{transform:rotate(1turn)}}
